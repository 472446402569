import { useRouter } from 'next/router';
import { useEffect } from 'react';

import MainLayout from '../layouts/MainLayout';
import { useAuthProvider } from '../providers/AuthProvider';

export default function Home() {
    const router = useRouter();
    const { isLoggedIn } = useAuthProvider();
    useEffect(() => {
        if (isLoggedIn) {
            router.replace('/analyses');
        } else {
            router.replace('/login');
        }
    }, []);
    return <MainLayout loader={true} />;
}
